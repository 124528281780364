import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import SVGInline from 'react-svg-inline'

import Facebook from 'images/icons/social/Facebook.svg'
import Twitter from 'images/icons/social/Twitter.svg'
import Instagram from 'images/icons/social/Instagram.svg'
import LinkedIn from 'images/icons/social/LinkedIn.svg'

const Social = (props) => {
    const blockClassName = 'social';

    let _element = (name) => {
        return blockClassName + '__' + name;
    }

    let _eachSite = (site, index) => {
        let icon;

        switch (site.name) {
            case 'Facebook':
                icon = Facebook
                break
            case 'Twitter':
                icon = Twitter
                break
            case 'Instagram':
                icon = Instagram
                break
            case 'LinkedIn':
                icon = LinkedIn
                break
            default:
                break
        }

        return (
            <li
                className = {_element('site')}
                key = {index}
            >
                <a
                    href = {site.url}
                    className = {_element('icon')}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className='u-accessible-hide'>{site.name}</span>
                    <SVGInline
                        svg = {icon}
                        className = {_element('logo')}
                        fill = "#fff"
                        cleanup = {true}
                    />
                </a>
            </li>
        )
    }

    return (
        <ul className={blockClassName}>
            {props.sites.map(_eachSite)}
        </ul>
    )
}


Social.propTypes = {
    sites: PropTypes.arrayOf(
        PropTypes.shape({
            site: PropTypes.string,
            url: PropTypes.string
        })
    )
}

const mapStateToProps = (state) => {
    return {
        sites: state.globalContent.invoke.contact.social
    }
}

export default connect(mapStateToProps, null)(Social)
