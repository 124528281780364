import React from 'react';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import LogoSvg from 'images/invoke-logo-light.svg';

const Logo = (props) => {
    return (
        <SVGInline
            svg = {LogoSvg}
            className = 'logo'
            fill = {props.color}
            cleanup = {true}
            width = {props.width}
            height = {props.height}
        />
    )
}

Logo.defaultProps = {
    color: '#3b3b3a',
    width: "91",
    height: "24"
}

Logo.propTypes = {
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
}

export default Logo;
